export enum SubscriptionStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FAILED = 'FAILED',
}

export enum SubscriptionType {
  AUTO_ENABLED = 'AUTO_ENABLED',
  REQUEST = 'REQUEST',
  ADMIN_ADD = 'ADMIN_ADD',
}

export enum SubscriptionToolCode {
  ir_ld = 'ir_ld',
  ir_da = 'ir_da',
  ir_compass = 'ir_compass',
  esdec_calc = 'esdec_calc',
  esdec_academy = 'esdec_academy',
  esdec_onsite = 'esdec_onsite',
  esdec_onsite_page = 'esdec_onsite_page',
  enstall_academy = 'enstall_academy',
  enstall_erp = 'enstall_erp',
  ecofasten_da = 'ecofasten_da',
  ecofasten_academy = 'ecofasten_academy',
}

export type SubscriptionModel = {
  status: SubscriptionStatus;
  toolCode: SubscriptionToolCode;
  subscriptionType: SubscriptionType;
  startDate: string;
  expiryDate: string;
  acceptedTermsAndConditions: string;
  requiredTermsAndConditions: string;
};
